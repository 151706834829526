.list-container {
  padding: 10px;
}

.table-container {
  width: 100%;
}

.list-header {
  padding: 8px !important;
  font-size: 14px;
}

.export-button {
  background-color: #1ea662;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-weight: 800;
  padding: 12px 18px;
  color: #fff;
  font-size: 16px;
  display: flex;
  gap: 10px;
  float: right;
}

.export-button:hover {
  opacity: 0.9;
}
.export-button:active {
  opacity: 0.8;
}
